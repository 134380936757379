import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../layout/headerHome";
import Footer from "../layout/footerUpdated";
import { Helmet } from "react-helmet";

const aboutbg = require("./../../assets/images/background/blog-bg.webp");

class Blog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            blogs: [],
            searchQuery: "",
            searchResults: [],
            categories: [],
            visibleBlogs: 12, // Initial number of blogs to show
            loadMoreClicked: true,
            currentPage: 1, // Initialize current page
            blogsPerPage: 9, // Number of blogs per page
            selectedCategory: "all",
        };
    }

    componentDidMount() {
        document.title = "Blog | Bridge Health";
        this.fetchBlogData();
    }

    fetchBlogData = () => {
        const siteUrl = `https://admin.bridgehealth.in`;
        fetch(`${siteUrl}/api/blog-listings?pagination[start]=0&pagination[limit]=999999&sort=id:desc&populate=*`)
        .then((response) => response.json())
        .then((data) => {
            console.log("Full Blog API Response:", data); // Log full response to debug
    
            const blogs = data.data.map((item) => ({
                id: item?.id,
                blog_slug: item?.attributes?.Slug,
                blog_heading: item?.attributes?.blog_reading,
                blog_title: item?.attributes?.blog_title,
                blog_paragraph: item?.attributes?.blog_paragraph,
                image: item?.attributes?.image?.data?.attributes?.url,
                blog_categories: item?.attributes?.category?.data
                    ? item.attributes.category.data.map(cat => cat.attributes?.Title)
                    : [], // Fix category mapping
            }));
    
            console.log("Parsed Blogs with Categories:", blogs);
            this.setState({ blogs });
        })
        .catch((error) => console.error("Error fetching blog data:", error));
    
        fetch(`${siteUrl}/api/categories`)
    .then((response) => response.json())
    .then((data) => {
        console.log("Categories API Response:", data);
        if (!data || !data.data) {
            console.error("Categories data is missing:", data);
            return;
        }
        const categories = data.data.map((item) => ({
            Name: item.attributes?.Title || "Unknown",
            Slug: item.attributes?.Slug || "",
        }));
        console.log("Parsed Categories:", categories);
        this.setState({ categories });
    })
    .catch((error) => console.error("Error fetching categories:", error));

        
    };

    handlePageChange = (pageNumber) => {
        this.setState({ currentPage: pageNumber }, () => {
            window.scrollTo({ top: 400, behavior: "smooth" });
        });
    };
    

    handleSearchInputChange = (event) => {
        const searchQuery = event.target.value;
        this.setState({ searchQuery }, () => this.handleSearch());
    };

    handleSearch = () => {
        const { searchQuery, blogs } = this.state;
        let filteredItems;
        if (searchQuery) {
            filteredItems = blogs.filter((item) =>
                item.blog_title.toLowerCase().includes(searchQuery.toLowerCase())
            );
            this.setState({ searchResults: filteredItems });
        } else {
            this.setState({ searchResults: '' });
        }
    };

    shouldApplyMarginTop = (index) => {
        const patternIndices = [3, 5, 6, 8, 9, 11, 12, 14, 15, 17, 18];
        return patternIndices.includes(index);
    };


    handleClearSearch = () => {
        this.setState({ searchQuery: "", searchResults: "" });
    };

    handleCategoryChange = (event) => {
        this.setState({ selectedCategory: event.target.value, currentPage: 1 });
    };

    loadMoreFn = () => {
        this.setState(prevState => ({
            visibleBlogs: -1, // Increase the number of visible blogs by 3
            loadMoreClicked: true
        }));
    };

    render() {
        const siteUrl = `https://admin.bridgehealth.in`;
        const { searchResults, blogs, searchQuery, categories, visibleBlogs, loadMoreClicked } = this.state;
        const { currentPage, blogsPerPage } = this.state;
        const indexOfLastBlog = currentPage * blogsPerPage;
        const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
        const filteredBlogs = this.state.selectedCategory === "all"
        ? blogs
        : blogs.filter(blog => 
            blog.blog_categories &&
            blog.blog_categories.some(category => 
                category.replace(/\s+/g, "-").toLowerCase() === this.state.selectedCategory.replace(/\s+/g, "-").toLowerCase()
            )
        );

        const currentBlogs = filteredBlogs.slice(indexOfFirstBlog, indexOfLastBlog);
        const totalPages = Math.ceil(blogs.length / blogsPerPage);

        console.log("Selected Category:", this.state.selectedCategory);
        console.log("Available Categories in Blogs:", this.state.blogs.map(blog => ({
            id: blog.id,
            categories: blog.blog_categories
        })));

        return (
            <>
                <Header />
                <Helmet>
                    <title>
                        Latest Health Blogs on preventive care, Corporate wellness | Bridge
                        Health
                    </title>
                    <meta
                        name="Blog"
                        content="Explore our informative blog for the latest articles & insights on health, wellness, & medical advancements. Stay informed & empowered with Bridge Health's blog."
                    />
                </Helmet>
                {/* Page Banner Section */}
                <section className="page-bann" style={{ backgroundImage: `url(${aboutbg})`, backgroundSize: "cover" }}>
                    <h1 className="colorwhite outline">BLOGS</h1>
                </section>

                {/* News Section */}
                <section className="blogs-n1">
                    <img className="blog-crcl crcl-1" src="/blog/crcl_01.png" alt="crcle_1" />
                    <img className="blog-crcl crcl-2" src="/blog/crcl_02.png" alt="crcle_2" />
                    <div>
                        <div className="container blogs-container-n1">
                            <div className="row search-bar-wrap">
                                <div className="col-lg-8 mx-auto">
                                    <div className="position-relative">
                                        {/* Search */}
                                        <div className="search-box-n1">
                                            <form className="">
                                                <div className="d-flex">
                                                    <input
                                                        type="text"
                                                        value={searchQuery}
                                                        onChange={this.handleSearchInputChange}
                                                        placeholder="Search Topic"
                                                        className="flex-1"
                                                    />
                                                    {searchQuery && (
                                                        <button
                                                            type="button"
                                                            className="clear-button"
                                                            onClick={this.handleClearSearch}
                                                        >
                                                            Clear
                                                        </button>
                                                    )}
                                                </div>

                                            </form>
                                        </div>
                                        {searchResults.length > 0 &&
                                            <div className="search-result-box">
                                                <div className="result-wrap-n1">
                                                    {searchResults.map((item) => (
                                                        <Link className="search-blog-item" key={item.id} to={`/blogs/${item.blog_slug}`}>
                                                            <img src={`${siteUrl}${item.image}`} alt={item.blog_title} rel="preload" />
                                                            
                                                            <article>
                                                                <h4>{item.blog_title}</h4>
                                                                <p>{item.blog_paragraph}</p>
                                                            </article>
                                                        </Link>
                                                    ))}
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="blog-cat-list">
                            <div className="container">
                                <div className="">
                                    <h2 className="blog-cat-title">Trending Blogs</h2>

                                         {/* <div className="category-tabs">
                                        <button 
                                            className={this.state.selectedCategory === "all" ? "active" : ""} 
                                            onClick={() => this.setState({ selectedCategory: "all", currentPage: 1 })}
                                        >
                                            All
                                        </button>
                                        {categories.map((category, index) => (
                                            <button 
                                                key={index} 
                                                className={this.state.selectedCategory === category.Name ? "active" : ""} 
                                                onClick={() => this.setState({ selectedCategory: category.Name.trim(), currentPage: 1 })}
                                            >
                                                {category.Name}
                                            </button>
                                        ))}
                                    </div>     */}

                                                                        <div className="row g-6 blog-list-row">
                                    {currentBlogs.map(blog => (
                                            <Link className="blog-item d-block col-lg-4" key={blog.id} to={"/blogs/" + blog.blog_slug}>
                                                <div className="image-wrap overflow-hidden">
                                                    <img className="object-fit-cover w-100" src={"https://admin.bridgehealth.in" + blog.image} alt={blog.blog_title} rel="preload" />
                                                </div>
                                                {blog.blog_categories.length > 0 && (
                                                <div className="category-names">
                                                    {blog.blog_categories.map((category, index) => (
                                                        <span key={index} className="category-badge">{category}</span>
                                                    ))}
                                                </div>
                                            )}

                                                <article>
                                                    <div className="btn-wrap text-center">
                                                        <button style={{color:"#0d0d0d"}}>{blog.blog_heading}</button>
                                                    </div>
                                                    <h4 style={{color:"#0d0d0d"}}>{blog.blog_title}</h4>
                                                    <div className="content">
                                                        {blog.blog_paragraph}
                                                    </div>
                                                    <div className="d-flex-blog justify-content-center">
                                                        <button className="def-btn-n1">Read More</button>
                                                    </div>
                                                </article>
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                                {/* {!loadMoreClicked && filteredBlogs.length > visibleBlogs && (
                                    <div className="loadmore-btn-n1">
                                        <button className="def-btn-n1 btn-1" onClick={this.loadMoreFn}>Load More</button>
                                    </div>
                                )} */}
                                <div className="cat-list-row">
                                    {categories && categories.map((category, index) => {
                                        let filteredBlogs = blogs.filter(blog_item =>
                                            blog_item.blog_categories.some(category => category.Slug === selectedCategory)
                                        );
                                        let displayedBlogs = loadMoreClicked ? filteredBlogs : filteredBlogs.slice(0, visibleBlogs);

                                        return (
                                            displayedBlogs.length > 0 && (
                                                <div className="blog-cat-item" key={index}>
                                                    <h2 className="blog-cat-title">{category.Name}</h2>
                                                    <div className="row g-4 blog-list-row">
                                                        {displayedBlogs.map(blog => (
                                                            <Link className="blog-item d-block col-lg-4" key={blog.id} to={"/blogs/" + blog.blog_slug}>
                                                                <div className="image-wrap overflow-hidden">
                                                                    <img className="object-fit-cover w-100" src={"https://admin.bridgehealth.in" + blog.image} alt={blog.blog_title} rel="preload" />
                                                                </div>
                                                                <article>
                                                                    <div className="btn-wrap text-center">
                                                                        <button style={{color:"#0d0d0d"}}>{blog.blog_heading}</button>
                                                                    </div>
                                                                    <h4 style={{color:"#0d0d0d"}}>{blog.blog_title}</h4>
                                                                    <div className="content">
                                                                        {blog.blog_paragraph}
                                                                    </div>
                                                                    <div className="d-flex-blog justify-content-center">
                                                                        <button className="def-btn-n1">Read More</button>
                                                                    </div>
                                                                </article>
                                                            </Link>
                                                        ))}
                                                    </div>

                                                    {!loadMoreClicked && filteredBlogs.length > visibleBlogs && (
                                                        <div className="loadmore-btn-n1">
                                                            <button className="def-btn-n1 btn-1" onClick={this.loadMoreFn}>Load More</button>
                                                        </div>
                                                    )}
                                                </div>
                                            )
                                        );
                                    })}
                                </div>


                            </div>
                        </div>
                        {totalPages > 1 && (
                            <div className="pagination-wrap text-center mt-4">
                                 <button
                                        className={`pagination-btn ${currentPage === 1 ? "disabled" : ""}`}
                                        onClick={() => currentPage > 1 && this.handlePageChange(currentPage - 1)}
                                    >
                                        Prev
                                </button>
                                {Array.from({ length: totalPages }).map((_, index) => (
                                    <button
                                        key={index + 1}
                                        onClick={() => this.handlePageChange(index + 1)}
                                        className={`pagination-btn ${currentPage === index + 1 ? "active" : ""}`}
                                    >
                                        {index + 1}
                                    </button>
                                ))}
                                <button
                                    className={`pagination-btn ${currentPage === totalPages ? "disabled" : ""}`}
                                    onClick={() => currentPage < totalPages && this.handlePageChange(currentPage + 1)}
                                >
                                    Next
                                </button>
                            </div>
                        )}
                        </div>
                </section>


                <Footer />
            </>
        );
    }
}

export default Blog;
