import React from "react";
import { useLocation } from "react-router-dom";

const Checkout = () => {
  const location = useLocation();
  const cart = location.state?.cart || []; // ✅ Correct way to access cart in v5

  return (
    <div>
      <h2>Checkout Page</h2>
      {cart.length > 0 ? (
        cart.map((item, index) => (
          <div key={index}>
            <h3>{item.attributes.name}</h3>
            <p>Price: ₹{item.attributes.discountedPrice}</p>
            <p>Quantity: {item.quantity}</p>
          </div>
        ))
      ) : (
        <p>Cart is empty</p>
      )}
    </div>
  );
};

export default Checkout;
