import React, { useContext } from "react";
import { CartContext } from "../../context/CartContext";
import { useHistory } from "react-router-dom"; // ✅ Correct Router Hook for v5
import "../../assets/css/cart.css";

const Cart = () => {
  const { cart, updateCart, removeFromCart } = useContext(CartContext);
  const history = useHistory(); // ✅ React Router v5 navigation

  const handleCheckout = () => {
    history.push("/checkout", { cart }); // ✅ Correct syntax in v5
  };

  // Calculate total MRP and discount
  const totalTests = cart.length;
  const totalMRP = cart.reduce(
    (sum, item) => sum + item.attributes.OriginalPrice * item.quantity,
    0
  );
  const totalDiscount = cart.reduce(
    (sum, item) =>
      sum +
      (item.attributes.OriginalPrice - item.attributes.discountedPrice) *
        item.quantity,
    0
  );
  const totalPrice = totalMRP - totalDiscount;

  return (
    <div className="cart-container">
      <h2>{totalTests} {totalTests === 1 ? "test" : "tests"} added</h2>

      <div className="cart-content">
        {/* Left Side: Cart Items */}
        <div className="cart-items">
          {cart.length > 0 ? (
            cart.map((item) => (
              <div key={item.id} className="cart-item">
                <img
                  src={item.attributes.image || "/placeholder.png"}
                  alt={item.attributes.name}
                  className="item-image"
                />
                <div className="item-details">
                  <h3>{item.attributes.name}</h3>
                  <p className="item-price">
                    ₹{item.attributes.discountedPrice}
                    <span className="original-price">
                      ₹{item.attributes.OriginalPrice}
                    </span>
                    <span className="discount">
                      {(
                        ((item.attributes.OriginalPrice -
                          item.attributes.discountedPrice) /
                          item.attributes.OriginalPrice) *
                        100
                      ).toFixed(0)}
                      % off
                    </span>
                  </p>
                </div>
                <div className="quantity-controls">
                    <button
                      className="quantity-btn"
                      onClick={() => updateCart(item.id, "decrease")}
                    >
                      −
                    </button>
                    <span className="quantity">{item.quantity}</span>
                    <button
                      className="quantity-btn"
                      onClick={() => updateCart(item.id, "increase")}
                    >
                      +
                    </button>
                  </div>
                <button className="remove-btn" onClick={() => removeFromCart(item.id)}>
                  Remove
                </button>
              </div>
            ))
          ) : (
            <p className="empty-cart">Your cart is empty.</p>
          )}
        </div>

        {/* Right Side: Bill Summary */}
        {cart.length > 0 && (
          <div className="bill-summary">
            <h3>Bill Summary</h3>
            <div className="summary-details">
              <p>
                Cart MRP <span>₹{totalMRP}</span>
              </p>
              <p className="discount-line">
                Total discount <span>-₹{totalDiscount}</span>
              </p>
              <hr />
              <p className="total-price">
                To be paid <span>₹{totalPrice}</span>
              </p>
            </div>

            {/* Billing Address Section */}
            <div className="billing-address">
              <h4>Billing address</h4>
              <p>Gurgaon</p>
              <button className="add-address-btn">Add Address</button>
            </div>

            <button className="checkout-btn" onClick={handleCheckout}>
              Continue
            </button>
          </div>
        )}
      </div>

      <p className="cart-note">
        Tata 1mg is a technology platform to facilitate transaction of
        business for pathology and radiology tests through various
        diagnostic lab service providers. For details, read{" "}
        <a href="#">terms and conditions</a>.
      </p>
    </div>
  );
};

export default Cart;
