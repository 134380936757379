// FAQComponent.js
import React, { useState } from 'react';

const FAQComponent = ({ faqs }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleAccordionClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className="faq-updated">
      <div className="faq-container">
        {/* Left Section */}
        <div className="faq-left">
          <h2>
            Frequently <span className="highlight1">Asked</span> Questions
          </h2>
        </div>

        {/* Right Section */}
        <div className="faq-right">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className={`faq-item ${activeIndex === index ? "active" : ""}`}
            >
              {/* Title */}
              <div
                className="faq-title"
                onClick={() => handleAccordionClick(index)}
              >
                {faq.question}
                <span className="faq-icon">
                  {activeIndex === index ? "−" : "+"}
                </span>
              </div>

              {/* Content */}
              <div
                className="faq-content"
                style={{
                  height: activeIndex === index ? "auto" : "0",
                  overflow: "hidden",
                  transition: "height 0.3s ease",
                }}
              >
                {faq.answer}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQComponent;
