import React from "react";
import { useRef } from "react";

const RotatingCircleText = () => {
  const textRef = useRef(null);

  return (
    <div className="center-container">
      <svg className="circles" width="100%" height="100%" viewBox="0 0 1400 1400">
        <defs>
          <path
            id="circle-1"
            d="M250,700.5A450.5,450.5 0 1 11151,700.5A450.5,450.5 0 1 1250,700.5"
          />
        </defs>
        <text
          ref={textRef}
          className="circles__text circles__text--1 rotating"
        >
          <textPath
            className="circles__text-path"
            xlinkHref="#circle-1"
            aria-label=""
            textLength="2830"
          >
            Scroll -for - a - healthier - and - stronger - life -&nbsp;
          </textPath>
        </text>
      </svg>

      <div className="arrow-down">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="48"
          height="48"
          viewBox="0 0 24 24"
          fill="none"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-arrow-down"
        >
          <line x1="12" y1="5" x2="12" y2="19"></line>
          <polyline points="19 12 12 19 5 12"></polyline>
        </svg>
      </div>

      <style jsx>{`
        .center-container {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 30vh;
          position: relative;
        }

        .circles {
          pointer-events: none;
          --dim: 30vmin; /* Adjust size */
          width: var(--dim);
          height: var(--dim);
        }

        .circles__text {
          text-transform: uppercase;
          transform-origin: center;
          will-change: transform, opacity;
        }

        .circles__text--1 {
          font-size: 110px;
          fill: #fff;
        }

        /* Rotating Animation */
        .rotating {
          animation: rotate 10s linear infinite;
        }

        @keyframes rotate {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }

        .arrow-down {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .feather.feather-arrow-down {
          animation: bounce 2s infinite;
        }

        @keyframes bounce {
          0%, 100% {
            transform: translateY(0);
          }
          50% {
            transform: translateY(10px);
          }
        }
      `}</style>
    </div>
  );
};

export default RotatingCircleText;
