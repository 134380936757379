import React, { useEffect, useRef } from "react";
import RotatingCircleText from "./RotatingCircleText";

const HeroSection = () => {
  const textRef = useRef(null);

  useEffect(() => {
    const textElement = textRef.current;
    if (textElement) {
      const chars = textElement.innerText.split("");
      textElement.innerHTML = chars
        .map((char, i) => `<span class="char">${char}</span>`)
        .join("");
    }
  }, []);

  return (
    <section className="hero-section">
      <div className="hero-content">
        <h1
          className="hero-title"  
        >
          Explore Exceptional Primary & <br />Preventive Healthcare Services
        </h1>
        
      </div>
      
      <video
        src="/images/landing-video.mp4"
        muted
        autoPlay
        loop
        className="hero-video"
      />
      <RotatingCircleText />
    </section>
  );
};

export default HeroSection;
    