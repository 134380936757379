import React, { Component } from 'react';
import Router from './markup/router';
import './assets/css/style.css'
import './assets/css/responsive.css'
import './assets/css/color.css'
import './assets/css/d.css'
import './assets/css/sidenav.css'
import './assets/css/JobListing.css'
import './assets/css/z1_custom_style.css'
import '../node_modules/react-phone-number-input/style.css'
import './assets/css/MedicalMinds.css'
import "./assets/css/HeroSection.css";
import "./assets/css/healthcare.css";
import "./assets/css/womencare.css";
import "./assets/css/home-new.css";
import "./assets/css/eldercare.css";
import './assets/css/fonts.css'; // Import the font globally

class App extends Component {
  render() {
    return (
      <div className="App">
        <Router />
      </div>
    );
  }
}

export default App;
