import React from "react";
import PropTypes from "prop-types";

const WellnessSection = ({ cardsData, isMobile, heading, highlight }) => {
  return (
    <section>
      <div className="sectiontwo">
        <h2 className="headingsecond">
          <span className="highlight1-women">{highlight}</span>
          <br />
          {heading}
        </h2>
      </div>
      <div className={`cardcontainer ${isMobile ? "mobile" : ""}`}>
        {cardsData.map((card, index) => (
          <div key={index} className="card-woman">
            <img src={card.image} alt={card.title} className="card-image" />
            <p className="solutiontext">
              <span className="solutionbold">{card.title.split(" ")[0]}</span>{" "}
              {card.title.split(" ").slice(1).join(" ")}
            </p>
          </div>
        ))}
      </div>
    </section>
  );
};

// Prop validation
WellnessSection.propTypes = {
  cardsData: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  isMobile: PropTypes.bool,
  heading: PropTypes.string.isRequired,
  highlight: PropTypes.string.isRequired,
};

WellnessSection.defaultProps = {
  isMobile: false,
};

export default WellnessSection;
