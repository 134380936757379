import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const TestimonialSlider = ({ testimonials = [] }) => {
  // Slider settings specific to testimonials
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "40px", // Add gap between slides
    autoplay: true,
    arrows: false, // Disable prev/next arrows for simplicity
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false, // Center mode off for better focus
        centerPadding: "0px", // No extra padding
        },
      },
    ],
  };

  return (
    <div className="testimonial-slider">
      <style>
        {`
          .testimonial-slider {
            padding: 40px 0px;
            background-color: #f4f7f9;
            border-radius: 10px;
          }

          .testimonial-slide-container {
            padding: 0 20px; /* Gap between slides */
          }

          .testimonial-slide {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            background-color: #ffffff;
            padding: 30px;
            border-radius: 10px;
            box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
            height: 240px;
            width: 97% !important;
          }
          .testimonial-content {
            color: #0d0d0d;
            font-size: 16px;
            font-weight: 400;
            margin-bottom: 15px;
          }
          .testimonial-author {
            display: flex;
            align-items: center;
            margin-top: 15px;
          }
          .author-image {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            margin-right: 15px;
          }
          .author-name {
            font-size: 16px;
            font-weight: 600;
            color: #0d0d0d;
          }
          
          @media (max-width: 768px){
            .testimonial-slide {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            background-color: #ffffff;
            padding: 30px;
            border-radius: 10px;
            box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
            height: 300px;
          }
          }
        `}
      </style>
      <Slider {...sliderSettings}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial-slide">
            <p className="testimonial-content">{testimonial.content}</p>
            <div className="testimonial-author">
              {/* <img
                src={testimonial.image}
                alt={testimonial.name}
                className="author-image"
              /> */}
              <span className="author-name">{testimonial.name}</span>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default TestimonialSlider;
