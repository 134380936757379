import React, { useEffect, useState, useContext } from "react";
import { CartContext } from "../../context/CartContext"; // Import CartContext
import { useHistory } from "react-router-dom";

const API_URL = "https://admin.bridgehealth.in/api/tests"; // Your Strapi API

const TestList = () => {
  const [tests, setTests] = useState([]);
  const { addToCart } = useContext(CartContext); // Use cart context
  const history = useHistory();

  useEffect(() => {
    fetch(`${API_URL}?populate=*`) // ✅ Ensure images are included
      .then((res) => res.json())
      .then((data) => {
        console.log("API Response:", data); // Log response
        if (data && data.data) {
          setTests(data.data);
        }
      })
      .catch((err) => console.error("Error fetching tests:", err));
  }, []);

  const handleAddToCart = (test) => {
    addToCart(test);
    history.push("/cart"); // ✅ Correct way to navigate in v5
  };

  return (
    <div>
      <h2>Test Listings</h2>
      {tests.length > 0 ? (
        tests.map((test) => {
          const imageUrl = test.attributes.testImage?.data?.attributes?.url
            ? `https://admin.bridgehealth.in${test.attributes.testImage.data.attributes.url}`
            : "/placeholder.png"; 

          return (
            <div key={test.id} className="test-card">
              <img src={imageUrl} alt={test.attributes.name} className="item-image" />
              <h3>{test.attributes.name}</h3>
              <h3>{test.attributes.title}</h3>
              <p>{test.attributes.description}</p>
              <h3>{test.attributes.OriginalPrice}</h3>
              <h3>{test.attributes.discountedPrice}</h3>
              <button style={{ backgroundColor: "#0d0d0d" }} onClick={() => handleAddToCart(test)}>
                Add to Cart
              </button>
            </div>
          );
        })
      ) : (
        <p>No tests available.</p>
      )}
    </div>
  );
};

export default TestList;
