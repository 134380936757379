import React, { useState, useEffect, useRef } from "react";
import "../../assets/css/new-website.css"
import Footer from "../layout/footerUpdated";
import Header from "../layout/headerHome";
import SliderLab from "../element/component/Banner_slider";
import ServiceSection from "../element/component/ServiceSectionMetabolic";
import FAQComponent from '../element/component/faq';
import WhyLab from '../element/component/why';
import TestimonialSlider from "../element/component/testimonial-slider";
import ReachToUsForm from "../element/ReachToUsForm";
import Popup_Main from "../element/component/Popup_Main";

const MetabolicPage = () => {

  const [isOpen, setIsOpen] = useState(false);
  const Counter = ({ targetValue, duration }) => {
    const [count, setCount] = useState(0);
    const [isAnimating, setIsAnimating] = useState(false);
  
    useEffect(() => {
      let startValue = 0;
      const increment = targetValue / (duration / 50);
  
      const updateCounter = () => {
        startValue += increment;
        if (startValue >= targetValue) {
          setCount(Math.ceil(targetValue));
          setIsAnimating(false); // End animation
        } else {
          setCount(Math.floor(startValue));
          setIsAnimating(true); // Start animation
          requestAnimationFrame(updateCounter);
        }
      };
  
      setIsAnimating(true); // Initialize animation
      updateCounter();
    }, [targetValue, duration]);
  
    return <h3 className={isAnimating ? "animate-counter" : ""}>{count}%</h3>;
  };

  const [activeIndex, setActiveIndex] = useState(0);
  
  const toggleAccordion = (index) => {
      setActiveIndex(activeIndex === index ? null : index);
   };

  const cardsData = [
    { title: "Pre-Diabetes", image: "/images/new-website/metabolic/diabetes-meta.webp" },
    { title: "Type-2 Diabetes ", image: "/images/new-website/metabolic/diabetes-type2-meta.webp" },
    { title: "Obesity", image: "/images/new-website/metabolic/fat-meta.webp" },
    { title: "PCOS/PCOD", image: "/images/new-website/metabolic/pcos-meta.webp" },
  ];
  
  const whyData = [
    { title: "Comprehensive Support for Long-term Sustainable Changes", image: "/images/new-website/metabolic/comprehensive-support.webp" },
    { title: "Professional Guidance by a Clinical Doctor", image: "/images/new-website/metabolic/doctor-guidance.webp" },
    { title: "Nutrition Insights and Personalised Diet Plan", image: "/images/new-website/metabolic/nutrition-insight.webp" },
    { title: "Fitness Insights and Customized Exercise Routines", image: "/images/new-website/metabolic/fitness-insight.webp" },
    { title: "Reduced Stress and Improved Sleep", image: "/images/new-website/metabolic/reduce-stress.webp" },
    { title: "Improve Energy, Mood, and Overall Metabolic Function", image: "/images/new-website/metabolic/metabolism.webp" },
];

const metaTypes = [
    {
        name: "Continuous Glucose Monitoring (CGM)",
        desc: "Get real-time insights into your blood sugar trends ",
        image: "/images/new-website/metabolic/CGM.webp",
      },
      {
        name: "Personalised Doctor Consultations",
        desc: "Receive tailored advice to improve metabolic health ",
        image: "/images/new-website/metabolic/personalized-doctor-consulting.webp",
      },
      {
        name: "Customised Nutrition Plans",
        desc: "Develop meal plans with expert that are easy-to-follow",
        image: "/images/new-website/metabolic/customized-nutrition.webp",
      },
  ];

  const metaTypessecond = [
    {
        name: "Fitness Assessments",
        desc: "Optimise your physical activity with dedicated fitness coach",
        image: "/images/new-website/metabolic/fitness-assessment.webp",
      },
      {
        name: "Ongoing Support",
        desc: "Regular consultations with nutritionists and fitness coaches for sustained results ",
        image: "/images/new-website/metabolic/ongoing-support.webp",
      },
      {
        name: "Holistic Results Tracking",
        desc: "Monitor your progress across energy levels, weight, and overall well-being.",
        image: "/images/new-website/metabolic/health-tracker.webp",
      },
  ];

const options = [
    {
      title: "Personalised Nutrition Plans ",
      content: "Every plan is crafted to match your lifestyle and health goals, ensuring sustainable results."
    },
    {
      title: "Expert Online & Offline Guidance",
      content: "Access the best dietician in Bangalore for weight loss or consult an online dietician near you for 24/7 support."
    },
    {
      title: "Holistic Tech Aided Focus on Wellness ",
      content: "Our approach combines balanced nutrition with practical strategies, and advanced technology for long-term health success."
    },
  ];

  const testimonials = [
    {
      content:
        "Bridge health offers services at affordable cost. Akshata at the front desk is very cooperative and good at coordinating and follow-ups. The staff are well spoken and carried out all tests with patience.",
      name: "Akshata J Lokhande",
      image: "/images/new-website/bridgehealth-icon-new.webp", // Replace with actual image URL
    },
    {
      content:
        "I am Prof Sundararaj living at PRIMUS REFLECTION. The staff are very courteous with a smile on their faces, including all the doctors. Special thanks to Ms. Akshita!",
      name: "Sundar Raj",
      image: "/images/new-website/bridgehealth-icon-new.webp", // Replace with actual image URL
    },
    {
      content:
        "Good , clean hygiene place ... Feels like your are at home with the services that provide... I have enrolled for gym here.. And the trainers are thorough professionals.. And the staff I have interacted with them during the seminars or presentations they are knowledgeable",
      name: "Shiva Kumar S",
      image: "/images/new-website/bridgehealth-icon-new.webp", // Replace with actual image URL
    },
    {
      content:
        "From sattva apartment Bridge healthy is useful for our patients.Mahendra the nursing staff is good and courteous.",
      name: "Susheela Sharan",
      image: "/images/new-website/bridgehealth-icon-new.webp", // Replace with actual image URL
    },
    {
      content:
        "Laxman is an excellent nurse looking after the retired community. Gentle in taking blood. Very polite and efficient.",
      name: "Dipak Rao",
      image: "/images/new-website/bridgehealth-icon-new.webp", // Replace with actual image URL
    },
  ];

  const faqs = [
    {
      question: "What is Bridge Health's Metabolic Program?",
      answer:
        "Bridge Health's Metabolic Program focuses on personalized lifestyle interventions to improve metabolic health and manage conditions like diabetes and obesity. ",
    },
    {
      question: "How can the Bridge Health's Metabolic Program help me manage my diabetes? ",
      answer: "The program uses tailored nutrition, exercise, and behavioural strategies to improve blood sugar levels and overall metabolic health. ",
    },
    {
      question: "What kind of ongoing support does Bridge Health provide during the program? ",
      answer: "Bridge Health offers regular consultations, progress tracking, and access to health coaches (doctors, nutritionists & fitness coaches) for personalized guidance and motivation. ",
    },
    {
      question: "What technology or tools are used in the Bridge Health Metabolic Program?  ",
      answer: "The program integrates wearable devices like a CGM device, mobile apps, and telehealth consultations for real-time monitoring and support. ",
    },
    {
      question: "What is the cost of the Bridge Health Metabolic Program? ",
      answer: "Costs vary based on the program's duration and features. Contact Bridge Health for specific pricing details. ",
    },
    {
        question: "What kind of results can I expect from the Bridge Health Metabolic Program? ",
        answer: "Participants often experience better blood sugar control, weight loss, and improved energy levels with sustained lifestyle changes. ",
      },
  ];


  const [isMobile, setIsMobile] = useState(false);
  const sectionRefs = useRef([]); // Refs for multiple sections/cards
  const [hasAnimated, setHasAnimated] = useState([]); // Animation tracking for cards

  // Resize event listener to detect mobile view
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    handleResize(); // Initialize on mount
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Scroll Animation Logic
  useEffect(() => {
    const handleScroll = () => {
      const updatedVisibility = sectionRefs.current.map((ref) => {
        if (ref) {
          const rect = ref.getBoundingClientRect();
          const windowHeight =
            window.innerHeight || document.documentElement.clientHeight;

          // Element becomes visible when its top is within viewport
          return rect.top <= windowHeight - 50;
        }
        return false;
      });

      setHasAnimated((prev) =>
        updatedVisibility.map((visible, index) => prev[index] || visible)
      );
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Trigger on mount to check initial visibility
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);


  return (
    <div>
      <Header/>
      <div className={`landingcontainer ${isMobile ? "mobile" : ""}`}>
        <div className={`leftContainer ${isMobile ? "mobile" : ""}`}>
          <h1>
          Every Body is Different {" "}
            <span className="highlight1"> </span> 
          </h1>
          <p className="paragraph-left">Our unique metabolic health program combines continuous glucose monitoring (CGM) data and expert guidance to boost your energy, control blood sugar, achieve your ideal weight, reduce stress, and embrace a healthier, happier you! </p>
          <button className="buttonlanding" onClick={()=>setIsOpen(true)}>Book a Free Consultation</button>
        </div>
        <div className="rightContainer">
          <img
            src="/images/new-website/metabolic/metabolic-bg.webp"
            alt="yoga-landing"
            className="imagelanding-meta imageFadeInUp"
          />
        </div>
      </div>
      
            <section>
            <div className="rightstrongcontainer">
            <h2 className="heading1">
            Transform Your Life by  
              <span className="highlight1"> Fixing Your Metabolism</span>
              <span className="line-break-heading"></span> with Met.Fit 
            </h2>
            </div>
              <ServiceSection
                cardsData={cardsData}
                isMobile={isMobile}
                highlight="For All Your Health Needs"
                heading="Personalised Offline & Online Yoga Classes"
                customClass="WellnessSectionWomen"
              />
              <div className="service-section-button">
                <button className="buttonlanding" onClick={()=>setIsOpen(true)}>Request a Call Back</button>
              </div>
            </section>

            <section>
                <div className="meta-types">
                <div className="rightstrongcontainer">
                    <h2 className="heading1">
                    Get Started on Your
                    <span className="highlight1"> Metabolic Health</span>
                    <span className="line-break-heading"></span> Journey Today
                    </h2>
                </div>
                <div className="yoga-type-card-section">
                        {metaTypes.map((meta, index) => (
                            <div
                            key={index}
                            className="yoga-type-card"
                            >
                            <img src={meta.image} alt={meta.name} className="leadership-image"/>
                            <h3 className="solutiontext"><span className="solutionbold">{meta.name.split(" ")[0]}</span>{" "}
                            {meta.name.split(" ").slice(1).join(" ")}</h3>
                            <p className="yoga-type-desc">{meta.desc}</p>
                            </div>
                        ))}
                </div>
                <div className="yoga-type-card-section">
                        {metaTypessecond.map((metasecond, index) => (
                            <div
                            key={index}
                            className="yoga-type-card"
                            >
                            <img src={metasecond.image} alt={metasecond.name} className="leadership-image" />
                            <h3 className="solutiontext"><span className="solutionbold">{metasecond.name.split(" ")[0]}</span>{" "}
                            {metasecond.name.split(" ").slice(1).join(" ")}</h3>
                            <p className="yoga-type-desc">{metasecond.desc}</p>
                            </div>
                        ))}
                </div>
                </div>
            </section>

        <section className="why-choose-section-physio">
          <h2 className="heading1">Why Choose <span className="highlight1">Bridge Health's</span> Metabolic Health Program</h2>
                    
          <WhyLab
            whyData={whyData} 
            dynamicText="Radiology" 
            dynamicParagraph="We have some of the best specialists who bring years of experience and offer evidence – based treatment to ensure best care for you" 
          />
      </section>

    <section>
        <div>
        <FAQComponent faqs={faqs} />
        </div>
    </section>

    <section>
    <div className="testimonials-container">
      {/* Left Side */}
      <div className="left-section-testimonial">
        <h2>
        Trust Us Based on What Our <span className="highlight1">Patrons Say</span>
        </h2>
        <h3>Overall Ratings</h3>
        <div className="ratings">
          <img
            src="/images/new-website/women-care/google-logo-1.webp"
            alt="Google Logo"
          />
          <div>
            <p>
              4.9{" "}
              <span className="stars">
                ⭐⭐⭐⭐⭐
              </span>
            </p>
            <p>Customer Reviews</p>
          </div>
        </div>
        <a href="https://www.google.com/search?q=bridgehealth&sca_esv=f8c3d57e75161cda&source=hp&ei=k3mQZ5CSHe_G1e8Psu7IkA4&iflsig=AL9hbdgAAAAAZ5CHoz7_cRGSTpek_a5zgM_qrQEKb84T&ved=0ahUKEwjQp-qYxIiLAxVvY_UHHTI3EuIQ4dUDCBk&uact=5&oq=bridgehealth&gs_lp=Egdnd3Mtd2l6IgxicmlkZ2VoZWFsdGgyCxAuGIAEGMcBGK8BMgUQABiABDIFEAAYgAQyBRAAGIAEMgUQABiABDIFEAAYgAQyBRAAGIAEMggQABiABBiiBDIIEAAYgAQYogQyCBAAGIAEGKIESNgdUABY_hpwAHgAkAEAmAHKAaABqg-qAQYwLjExLjG4AQPIAQD4AQGYAgygAuAPwgIIEAAYgAQYsQPCAgsQABiABBixAxiDAcICBRAuGIAEwgILEC4YgAQYsQMYgwHCAg4QLhiABBixAxiDARiKBcICDhAAGIAEGLEDGIMBGIoFwgIIEC4YgAQYsQPCAg4QLhiABBixAxjRAxjHAcICCxAAGIAEGLEDGIoFwgILEC4YgAQYsQMY1AKYAwCSBwYwLjExLjGgB86VAQ&sclient=gws-wiz#lrd=0x3bae1571e6b9bbaf:0xc485c6444a9e56b4,3,,,," target="_blank" rel="noopener noreferrer">
        <button className="buttonmaphome-review">Write a Review</button>
        </a>
      </div>

      {/* Right Side */}
      <div className="right-section-testimonial">
        <div className="testimonial-page">
      <TestimonialSlider testimonials={testimonials} />
    </div>
      </div>
    </div>
    </section>
    <Popup_Main isOpen={isOpen} onClose={() => setIsOpen(false)} >
        <ReachToUsForm submitted={() => setIsOpen(false)} />
        </Popup_Main>
        <Footer />
    </div>
  );
};

export default MetabolicPage;
