import React, { useState } from "react";
import Slider from "react-slick"; // Ensure you have `react-slick` installed
import "slick-carousel/slick/slick.css"; // Slick styles
import "slick-carousel/slick/slick-theme.css"; // Optional theme
import Popup_Main from "../component/Popup_Main";
import ReachToUsForm from "../ReachToUsForm";

const BloodTestSlider = ({ tests, BloodTestsettings }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div>
    <Slider {...BloodTestsettings}>
      {tests.map((test, index) => (
        <div key={index} className="test-card">
          <div className="test-slider-main">
            <div className="test-slider-title-bar">
                <div className="test-slider-title">
                    <h3>{test.title}</h3>
                </div>
                <div className="test-slider-price">
                    <span className="test-slider-original-price">{test.originalPrice}</span>{" "}
                    <span className="test-slider-current-price">{test.price}</span>{" "}
                    <span className="test-slider-discount">{test.discount}</span>
                </div>
            </div>
          
            <div className="test-slider-details">
                <div className="test-slider-details-header">
                    <div className="test-slider-details-parameter">
                        <img
                          src="images/new-website/radiology/test-flask.webp"
                          alt="radiology"
                          className="imagelanding imageFadeInUp"
                        />
                      <p>{test.testsIncluded}</p>
                    </div>
                    <div className="test-slider-details-others">

                    </div>
                </div>
                <div className="test-slider-buttons">
                    <a href="tel:+91 8047093866">
                    <button className="add-button">Call Now</button>
                    </a>
                    <button className="add-button" onClick={()=>setIsOpen(true)}>Request a Call Back</button>
                </div>
            </div>
          </div>
        </div>
        
      ))}
    </Slider>
    <Popup_Main isOpen={isOpen} onClose={() => setIsOpen(false)} >
        <ReachToUsForm submitted={() => setIsOpen(false)} />
        </Popup_Main>
    </div>
  );
};
export default BloodTestSlider;