import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const Banner_Slider_Women = ({ sliderSettings, slides = [] }) => {
  const [isOpen, setIsOpen] = useState(false);

  // Default slider settings
  const defaultSettings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: true,
    prevArrow: (
      <button className="custom-arrow custom-prev">
        <i className="fa fa-angle-left" aria-hidden="true"></i>
      </button>
    ),
    nextArrow: (
      <button className="custom-arrow custom-next">
        <i className="fa fa-angle-right" aria-hidden="true"></i>
      </button>
    ),
  };

  // Merge default settings with provided sliderSettings
  const settings = { ...defaultSettings, ...sliderSettings };

  return (
    <>
      <div className="banner-slider-women">
        <style>
          {`
            .custom-slider .slick-slide-new {
              padding: 0 8px;
              margin-top: 50px;
            }
            .custom-slider .custom-slide {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              padding: 30px;
              text-align: center;
              background: #ffffff;
              border-radius: 10px;
            }
            .custom-slider .custom-slide h2 {
              font-size: 40px;
              font-weight: bold;
              color: #0d0d0d;
              margin-bottom: 10px;
            }
            .custom-slider .custom-slide h2 .highlight {
              color: #871629; /* Highlighted text color */
            }
            .custom-slider .custom-slide p {
              font-size: 18px;
              color: #666;
              margin-bottom: 20px;
            }
            .custom-slider .custom-slide img {
              width: 60%;
              max-width: 500px;
              margin-top: 20px;
              border-radius: 10px;
              margin-bottom: -40px;
            }
            .custom-arrow {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              background-color: #fff;
              border: none;
              border-radius: 50%;
              box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
              width: 40px;
              height: 40px;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              z-index: 1000;
            }
            .custom-prev {
              left: -50px;
            }
            .custom-next {
              right: -50px;
            }
            .custom-arrow i {
              font-size: 20px;
              color: #333;
            }
            .custom-arrow:hover {
              background-color: #b22234; /* Highlight color for hover */
            }
            .custom-arrow:hover i {
              color: #fff;
            }
          
          `}
        </style>
        <Slider {...settings} className="custom-slider">
          {slides.map((slide, index) => (
            <div key={index} className="custom-slide">
              <h2>
              <span className="highlight">{slide.heading}{" "} </span><br />
                {slide.highlightedText}
              </h2>
              <p>{slide.subheading}</p>
              {slide.image && <img src={slide.image} alt={`Slide ${index + 1}`} />}
            </div>
          ))}
        </Slider>
      </div>
    </>
     );
};

export default Banner_Slider_Women;
