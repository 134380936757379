import React, { useState } from "react";
import Header from "../layout/headerHome";
import Footer from "../layout/footerUpdated";
import HeroSection from "../element/component/HeroSection";
import HealthCareTemplate from "../element/component/HealthCareTemplate";
import MedicalMinds from "../element/component/MedicalMinds";
import TestimonialSlider from "../element/component/testimonial-slider";
import ReachToUsForm from "../element/ReachToUsForm";
import Popup_Main from "../element/component/Popup_Main";

const Homeupdated = () => {
  const [activeTab, setActiveTab] = useState("clinics");
  const [isOpen, setIsOpen] = useState(false);

  const whyData = [
    { title: "Evidence-Based, Customised Health Solutions", image: "/images/new-website/home-new/fingerprint.webp" },
    { title: "Proactive Health Approach", image: "/images/new-website/home-new/healthcare-home.webp" },
    { title: "Expert Care Coupled With AI-powered Screenings", image: "/images/new-website/home-new/ai.webp" },
    { title: "Elderly Care Services, Redefined", image: "/images/new-website/home-new/elder-care-home.webp" },
    { title: "Women’s Health Care, Prioritised", image: "/images/new-website/home-new/women-care-home.webp" },
  ];

  const testimonials = [
    {
      content:
        "Bridge health offers services at affordable cost. Akshata at the front desk is very cooperative and good at coordinating and follow-ups. The staff are well spoken and carried out all tests with patience.",
      name: "Akshata J Lokhande",
      image: "/images/new-website/bridgehealth-icon-new.webp", // Replace with actual image URL
    },
    {
      content:
        "I am Prof Sundararaj living at PRIMUS REFLECTION. The staff are very courteous with a smile on their faces, including all the doctors. Special thanks to Ms. Akshita!",
      name: "Sundar Raj",
      image: "/images/new-website/bridgehealth-icon-new.webp", // Replace with actual image URL
    },
    {
      content:
        "Good , clean hygiene place ... Feels like your are at home with the services that provide... I have enrolled for gym here.. And the trainers are thorough professionals.. And the staff I have interacted with them during the seminars or presentations they are knowledgeable",
      name: "Shiva Kumar S",
      image: "/images/new-website/bridgehealth-icon-new.webp", // Replace with actual image URL
    },
    {
      content:
        "From sattva apartment Bridge healthy is useful for our patients.Mahendra the nursing staff is good and courteous.",
      name: "Susheela Sharan",
      image: "/images/new-website/bridgehealth-icon-new.webp", // Replace with actual image URL
    },
    {
      content:
        "Laxman is an excellent nurse looking after the retired community. Gentle in taking blood. Very polite and efficient.",
      name: "Dipak Rao",
      image: "/images/new-website/bridgehealth-icon-new.webp", // Replace with actual image URL
    },
  ];

    const firstRow = whyData.slice(0, 3); // First three cards
    const secondRow = whyData.slice(3, 5); // Last two cards

  return (
    <>
      <Header />
      <section className="banner-slder-t1">
        {/* <Banner_slider /> */}
        <HeroSection />
      </section>

      <section className="services-home">
        <HealthCareTemplate />
      </section>

      <section>
          <div className="health-journey-container">
              {/* Left Column - Image */}
              <div className="health-journey-left">
                <img
                  src="/images/girl.png"
                  alt="Health Journey"
                  className="health-journey-image"
                />
              </div>

              {/* Right Column - Text */}
              <div className="health-journey-right">
                <h2 className="health-journey-text">
                Confused About 
                  <span className="highlighted-text"> What Lab Tests
                  </span>{" "}
                  to Take?{" "}
                </h2>
                <p>
                Answer questions about your current health conditions and get personalized lab tests recommended along with a list of radiology tests and vaccines.
                </p>
                <div className="button-container">
                  <a href="https://bhcare.bridgehealthgroup.com/smartGuide/start" target="_blank">
                  <button className="buttonjourney">Start Now</button>
                  </a>
                </div>
              </div>
            </div>
        </section>
      
        <div id="clinicPharma" className="clinic-pharma">
            <h2> <span className="highlight-text">50+ Clinics Strong</span>, Now Here to Serve You<br /> Find Our Clinics & Pharmacies</h2>
            <div className="bridge-health-app"> 
            <div className="bridge-health-sidebar">
                <div
                className={`bridge-health-tab ${
                    activeTab === "clinics" ? "bridge-health-active-tab" : ""
                }`}
                onClick={() => setActiveTab("clinics")}
                >
                <span className="bridge-health-icon">
                    <i className="fas fa-clinic-medical"></i>
                </span>
                Bridge Health Clinics Near You
                </div>
                <div
                className={`bridge-health-tab ${
                    activeTab === "pharmacies" ? "bridge-health-active-tab" : ""
                }`}
                onClick={() => setActiveTab("pharmacies")}
                >
                <span className="bridge-health-icon">
                    <i className="fas fa-pills"></i>
                </span>
                Bridge Health Pharmacies Near You
                </div>
                <div className="care-center-desk">
                <p>Want to open a care center inside your residential complex or corporate premise?</p>
                <button className="buttonmaphome-nearby" onClick={()=>setIsOpen(true)}>Request a Call Back</button>
                </div>
            </div>
            <div className="bridge-health-content">
                {activeTab === "clinics" && (
                <iframe
                    loading="lazy"
                    src="https://maps.google.com/maps?q=Bridgehealth%20clinics&amp;t=m&amp;z=10&amp;output=embed&amp;iwloc=near"
                    title="Bridgehealth clinics"
                    aria-label="Bridgehealth clinics"
                ></iframe>
                )}
                {activeTab === "pharmacies" && (
                <iframe
                    loading="lazy"
                    src="https://maps.google.com/maps?q=Bridgehealth%20pharmacy&amp;t=m&amp;z=10&amp;output=embed&amp;iwloc=near"
                    title="Bridgehealth pharmacy"
                    aria-label="Bridgehealth pharmacy"
                ></iframe>
                )}
                <div className="care-center-mob">
                <p>Want to open a care center inside your residential complex or corporate premise?</p>
                <button className="buttonmaphome-nearby">Request a Call Back</button>
                </div>
            </div>
            </div>
        </div>

        <section className="business-leaders-section">
              <div className="business-leaders-container-left">
                {/* Left Column - Text */}
                <div className="business-para">
                  <h2>
                    Let Us Take Care Of <span className="highlight-text">Business Leaders</span> With Executive-Level Care
                  </h2>
                  <p>
                    Customised Holistic Health Solutions For CEOs And Senior Managers,
                    Empowering Them To Focus On Business Growth
                  </p>
                  <button className="buttonmaphome-nearby" onClick={()=>setIsOpen(true)}>Connect with Us</button>
                </div>

                {/* Right Column - Sliders */}
                <div className="business-leaders-container-right">  
                    <div class="slider">
                        <div class="slider-content">
                            <img src="/images/new-website/home-scroll/digit.webp" alt="Logo 1" />
                            <img src="/images/new-website/home-scroll/mantri.webp" alt="Logo 2" />
                            <img src="/images/new-website/home-scroll/bechem.webp" alt="Logo 3" />
                            <img src="/images/new-website/home-scroll/acciona.webp" alt="Logo 4" />
                            <img src="/images/new-website/home-scroll/indiqube.webp" alt="Logo 5" />
                            <img src="/images/new-website/home-scroll/digit.webp" alt="Logo 1" />
                            <img src="/images/new-website/home-scroll/mantri.webp" alt="Logo 2" />
                            <img src="/images/new-website/home-scroll/bechem.webp" alt="Logo 3" />
                            <img src="/images/new-website/home-scroll/acciona.webp" alt="Logo 4" />
                            <img src="/images/new-website/home-scroll/indiqube.webp" alt="Logo 5" />
                        </div>
                    </div>
                    <div class="slider slider-row-reverse">
                        <div class="slider-content">
                            <img src="/images/new-website/home-scroll/simpl.webp" alt="Logo 1" />
                            <img src="/images/new-website/home-scroll/iitpl.webp" alt="Logo 2" />
                            <img src="/images/new-website/home-scroll/vertex.webp" alt="Logo 3" />
                            <img src="/images/new-website/home-scroll/nivetti.webp" alt="Logo 4" />
                            <img src="/images/new-website/home-scroll/prakat (1).webp" alt="Logo 5" />
                            <img src="/images/new-website/home-scroll/simpl.webp" alt="Logo 1" />
                            <img src="/images/new-website/home-scroll/iitpl.webp" alt="Logo 2" />
                            <img src="/images/new-website/home-scroll/vertex.webp" alt="Logo 3" />
                            <img src="/images/new-website/home-scroll/nivetti.webp" alt="Logo 4" />
                            <img src="/images/new-website/home-scroll/prakat (1).webp" alt="Logo 5" />
                        </div>
                    </div>
                    <div class="slider">
                        <div class="slider-content">
                            <img src="/images/new-website/home-scroll/consark.webp" alt="Logo 1" />
                            <img src="/images/new-website/home-scroll/bhoruka.webp" alt="Logo 2" />
                            <img src="/images/new-website/home-scroll/synthesis.webp" alt="Logo 3" />
                            <img src="/images/new-website/home-scroll/genei.webp" alt="Logo 4" />
                            <img src="/images/new-website/home-scroll/eagle-detective-agency.webp" alt="Logo 5" />
                            <img src="/images/new-website/home-scroll/consark.webp" alt="Logo 1" />
                            <img src="/images/new-website/home-scroll/bhoruka.webp" alt="Logo 2" />
                            <img src="/images/new-website/home-scroll/synthesis.webp" alt="Logo 3" />
                            <img src="/images/new-website/home-scroll/genei.webp" alt="Logo 4" />
                            <img src="/images/new-website/home-scroll/eagle-detective-agency.webp" alt="Logo 5" />
                        </div>
                    </div>
                </div>
              </div>
            </section>
        
        <section>
        <div className="bridge-gap">
        <h2>Meet Our Team of Experts Who’ll <span className="highlight-text"><br />#BridgeTheGap</span> to Your Healthiest Self </h2>
        </div>
        <MedicalMinds/>
        </section>

        <section className="why-choose-section">
      <h2>
        Why Choose <span className="highlight-text">Bridge Health?</span>
      </h2>
      <div className="whycontainerbox-home">
        {/* First Row */}
        <div className="why-row">
          {firstRow.map((card, index) => (
            <div key={index} className="whyboxblue">
              <img src={card.image} alt={card.title} className="whyimage" />
              <p className="solutiontexthome">
                <span className="solutionbold">{card.title.split(" ")[0]}</span>{" "}
                {card.title.split(" ").slice(1).join(" ")}
              </p>
            </div>
          ))}
        </div>
        {/* Second Row */}
        <div className="why-row second-row">
          {secondRow.map((card, index) => (
            <div key={index} className="whyboxblue">
              <img src={card.image} alt={card.title} className="whyimage" />
              <p className="solutiontexthome">
                <span className="solutionbold">{card.title.split(" ")[0]}</span>{" "}
                {card.title.split(" ").slice(1).join(" ")}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
    <section>
    <div className="testimonials-container" style={{marginTop: "70px"}}>
      {/* Left Side */}
      <div className="left-section-testimonial">
        <h1>
        Trust Us Based on What Our <span style={{color:"#0f6bb4"}}>Patrons Say</span>
        </h1>
        <h3>Overall Ratings</h3>
        <div className="ratings">
          <img
            src="/images/new-website/women-care/google-logo-1.webp"
            alt="Google Logo"
          />
          <div style={{marginBottom:"30px"}} >
            <p>
              4.9{" "}
              <span className="stars">
                ⭐⭐⭐⭐⭐
              </span>
            </p>
            <p>Customer Reviews</p>
          </div>
        </div>
        <a href="https://www.google.com/search?q=bridgehealth&sca_esv=f8c3d57e75161cda&source=hp&ei=k3mQZ5CSHe_G1e8Psu7IkA4&iflsig=AL9hbdgAAAAAZ5CHoz7_cRGSTpek_a5zgM_qrQEKb84T&ved=0ahUKEwjQp-qYxIiLAxVvY_UHHTI3EuIQ4dUDCBk&uact=5&oq=bridgehealth&gs_lp=Egdnd3Mtd2l6IgxicmlkZ2VoZWFsdGgyCxAuGIAEGMcBGK8BMgUQABiABDIFEAAYgAQyBRAAGIAEMgUQABiABDIFEAAYgAQyBRAAGIAEMggQABiABBiiBDIIEAAYgAQYogQyCBAAGIAEGKIESNgdUABY_hpwAHgAkAEAmAHKAaABqg-qAQYwLjExLjG4AQPIAQD4AQGYAgygAuAPwgIIEAAYgAQYsQPCAgsQABiABBixAxiDAcICBRAuGIAEwgILEC4YgAQYsQMYgwHCAg4QLhiABBixAxiDARiKBcICDhAAGIAEGLEDGIMBGIoFwgIIEC4YgAQYsQPCAg4QLhiABBixAxjRAxjHAcICCxAAGIAEGLEDGIoFwgILEC4YgAQYsQMY1AKYAwCSBwYwLjExLjGgB86VAQ&sclient=gws-wiz#lrd=0x3bae1571e6b9bbaf:0xc485c6444a9e56b4,3,,,," target="_blank" rel="noopener noreferrer">
        <button className="buttonmaphome-review">Write a Review</button>
        </a>
      </div>

      {/* Right Side */}
      <div className="right-section-testimonial">
        <div className="testimonial-page">
      <TestimonialSlider testimonials={testimonials} />
    </div>
      </div>
    </div>
    </section>
    <section>
      <div className="home-contact-main">
        {/* Left Side */}
        <div className="home-contact">
          <h2>
            <span className="highlight1">Get in Touch</span>
          </h2>
          <p>We are your trusted partner in comprehensive healthcare. <br />Reach out to us for inquiries, appointments, or support, and let us assist you on your journey to better health.</p>
        </div>
        <div>
        <ReachToUsForm/>
        </div>
      </div>
    </section>
    <Popup_Main isOpen={isOpen} onClose={() => setIsOpen(false)} >
        <ReachToUsForm submitted={() => setIsOpen(false)} />
        </Popup_Main>
    <Footer />
    </>
  );
};

export default Homeupdated;
