// WhyChooseRadiology.js
import React from 'react';

const WhyChooseRadiology = ({ whyData }) => {
  return (
    <section className="why-choose-section-radio">
     
      <div className="whycontainerbox-radio">
        {whyData.map((card, index) => (
          <div
            key={index}
            // Uncomment and use the following lines if animations or hover effects are needed
            // className={`card fade-in-up ${
            //     hasAnimated[index] ? "visible" : ""
            // }`}
            // onMouseEnter={() => setHoveredSolutionIndex(index)}
            // onMouseLeave={() => setHoveredSolutionIndex(null)}
            // ref={(el) => (sectionRefs.current[index] = el)}
          >
            <div className="whyboxblueradio">
              <img
                src={card.image}
                alt={card.title}
                className="whyimage"
              />
              <p className="solutiontext">
                <span className="solutionbold">{card.title.split(" ")[0]}</span>{" "}
                {card.title.split(" ").slice(1).join(" ")}
              </p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default WhyChooseRadiology;
