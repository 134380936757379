import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { CartProvider } from "./context/CartContext"; // Import CartProvider
import { BrowserRouter } from "react-router-dom";

ReactDOM.render(
<BrowserRouter>
  <CartProvider> 
    <App />
  </CartProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

serviceWorker.unregister();
