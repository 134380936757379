import React, { Component } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import FormValidation from "./formvalidation";
import { Helmet } from "react-helmet";
import LeadClinical from "./lead-clinical";
class aboutUs extends Component {
  componentDidMount() {
    document.title = "About Us |  Bridge Health Medical and Digital Solution Private Limited";
  }

  constructor(props) {
    super(props);

    this.state = {
      username: "",
      email: "",
      phone: "",
      message: "",
    };
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ ...this.state, [name]: value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
  };

  saveData = async (e) => {
    e.preventDefault();

    const { email, username, phone, message } = this.state;

    const res = await fetch("http://localhost:8000/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        username,
        phone,
        message,
      }),
    });

    const data = await res.json();

    if (data.status === 401 || !data) {
      console.log("error");
    } else {
      this.setState({
        show: true,
        email: "",
        username: "",
        phone: "",
        message: "",
      });
      console.log("Data saved");
    }
  };

  sendEmail = async (e) => {
    e.preventDefault();

    const { email, username } = this.state;

    const res = await fetch("http://localhost:8000/register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        username,
      }),
    });

    const data = await res.json();
    if (data.status === 401 || !data) {
      console.log("error");
    } else {
      this.setState({ show: true, email: "", username: "" });
      console.log("Email sent");
    }
  };

  render() {
    return (
      <>
        <Header />
        <Helmet>
          <title>
            Personalized Care Continuum -Medical & Digital Health Services
            |Bridge Health Medical and Digital Solution Private Limited
          </title>
          <meta
            name="description"
            content=" Bridge Health Medical and Digital Solution Private Limited is one stop health solution for all preventive & holistic healthcare with personalized annual health plans. & There is a plan to fit the specific health needs of everybody - your spouse, parents, kids & YOU. Know more about  Bridge Health Medical and Digital Solution Private Limited, JP Nagar 2nd phase Bangalore."
          />
        </Helmet>
        <section className="internalpage style-two aboutback image-mobile-terms">
          <div className="auto-container ">
            <div className="aboutcont ">
              <div className="aboutbox ">
                <h3 className="underline">OUR VISION</h3>
                <p className="textaligncenter">
                  {" "}
                  Spread Happiness <br></br> Through Good Health
                </p>
              </div>
              <div className="aboutbox ">
                <h3 className="underline">OUR MISSION</h3>
                <p className="textaligncenter">
                  {" "}
                  Create & deliver <b>primary</b>, predictive & <b>preventive</b> healthcare <b>solutions</b> with coordinated care & measurable <b>outcomes.</b> <br></br> Companion For Life
                </p>
              </div>
            </div>
          </div>
        </section>

        <div className="auto-container marginTop50 marginTop0">
          <div className="row align-items-center reverseFlex">
            <div className="col-lg-6">
              <div className="sec-title squrebox">
                <p className="font22">
                  <span className="headeingcolorblue">Bridge Health</span> specializes in preventive and pro-active healthcare, offering a host of services to individuals and corporates – from Lab Tests, Doctor Consultations, Fitness, Yoga, Nutrition, Adult Vaccinations, Stress & Sleep Management and Emotional Wellness.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <img
                srcSet={require("../../assets/images/shape/apage2.webp")}
                alt="about"
                className="widthimg imgFloatRight"
                rel="preload"
              />
            </div>
          </div>
        </div>

        <div className="auto-container contact-section">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="aboutgraph">
                <img
                  srcSet={require("../../assets/images/shape/apage3.webp")}
                  alt="about"
                  rel="preload"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="contact-form-area">
                <div className="sec-title squrebox">
                  <h3>The Bridge Health Advantage </h3>
                  <ul className="about-sec-2-a2">
                    <li><b>Relevant Customization</b> - Customized Health Checks based on Employee Age Group & Risk Factors</li>
                    <li><b>Risk Assessment</b> -  Detailed Future Health Risk Assessment & Health Scores (7 Key Factors)</li>
                    <li><b>Useful Health Insights</b> -  In-Depth Doctor Consultations & Health Insights</li>
                    <li><b>AI-enabled Screenings</b> - Quick, hassle and pain-free screenings for breast, bone and knee health</li>
                    <li><b>Specialized Health Services</b> - Expert-crafted annual health plans and checks for women and the elderly</li>
                    <li><b>High Convenience</b> - Offers In-clinic, At-office locations (on-site) and At-home services</li>
                    <li><b>Secure Health Records</b> - Digitized Individual Health Reports & Medical Records</li>
                    <li><b>Ease of Engagement</b> - Dedicated Relationship Manager + BH App (iOS / Android)</li>
                  </ul>
                  {/* <p className="font22">
                    With our in-house specialized clinical and support team
                    along with our credentialed affiliate partners, we have put
                    together a personalized physical and digital ecosystem for
                    your continued wellbeing. The promise this program delivers
                    is to make available a comprehensive and continuing
                    Healthcare Companionship that is personalized for you and
                    your family. While the program is predicated on prevention,
                    it is also designed to provide Healthcare Help and Support
                    as and when required.
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <section className="heath-margin">
          <div className="auto-container">
            <div className="row reverseFlex">
              <div className="col-lg-5">
                <div className="contact-form-area">
                  <div className="sec-title squrebox">
                    <p className="font22">
                      We, at Bridge Health Medical and Digital Solution Private Limited, believe that a proactive focus on
                      personalized healthcare and swift curative actions to
                      mitigate risks are of paramount importance to each one of
                      us. Our journey is all about being your trusted{" "}
                      <span className="headeingcolorblue">
                        “Healthcare Companion for Life”.
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="image-wrapper">
                  <div className="image-one">
                    <img
                      srcSet={require("../../assets/images/shape/graph.webp")}
                      alt="about"
                      className="width84"
                      rel="preload"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section className="marginTop80 about-form-reach-mobile">
          <div className="auto-container">
            <div className="sec-title text-center">
              <h2>
                {" "}
                <span className="headeingcolorblue">REACH </span>{" "}
                <span className="headeingcolorblack">OUT TO US</span>
                <br></br>
                <p style={{ fontSize: 22 + "px" }}>
                  There is a plan to fit the specific health needs of everybody
                  - your spouse, parents, kids & YOU! <br></br>{" "}
                  <span className="headeingcolorblue">
                    Talk to our health advisor today!!
                  </span>
                </p>
              </h2>
            </div>
          </div>
        </section>
        <div className="auto-container about-form-reach-mobile">
          <div className="row">
            <div className="col-lg-6">
              <div className="image-wrapper">
                <div className="image-one">
                  <img
                    className="reachout"
                    srcSet={require("../../assets/images/shape/contact.webp")}
                    alt="about"
                    rel="preload"
                  />
                </div>
              </div>
            </div>
            <div>
              <FormValidation />
            </div>
          </div>
        </div>
        <div className="disply-on-mobile">
          <LeadClinical />
        </div>

        <Footer />
      </>
    );
  }
}

export default aboutUs;
