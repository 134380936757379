import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Popup_Main from "./Popup_Main";
import ReachToUsForm from "../ReachToUsForm";

const Banner_slider = ({ sliderSettings, slides = [] }) => {
  const [isOpen, setIsOpen] = useState(false);

  // Default slider settings
  const defaultSettings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
   
  };

  // Merge default settings with provided sliderSettings
  const settings = { ...defaultSettings, ...sliderSettings };

  return (
    <>
      <div className="banner-slider-logo">
        <style>
          {`
            .def-slider .slick-slide {
              padding: 0 8px;
            }
            .def-slider .slick-list {
              margin: 0 -0%;
            }
            .def-slider img {
              width: 100%;
              object-fit: cover;
              border-radius: 10px;
            }
            .def-slider .mob {
              display: none;
            }
            @media (max-width: 768px) {
              .def-slider .desk {
                display: none;
              }
              .def-slider .mob {
                display: block;
              }
            }
          `}
        </style>
        <Slider {...settings} className="def-slider theme_carousel">
          {slides.map((slide, index) => (
            <div
              key={index}
              className="d-flex flex-column justify-content-center align-items-center position-relative bann-form-a5"
            >
              <img className="desk" src={slide.desktop} alt={`Slide ${index + 1}`} />
              {/* <img className="mob" src={slide.mobile} alt={`Slide ${index + 1}`} /> */}
            </div>
          ))}
        </Slider>
      </div>

      {/* Popup for Call Back Form */}
      <Popup_Main isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <ReachToUsForm submitted={() => setIsOpen(false)} />
      </Popup_Main>
    </>
  );
};

export default Banner_slider;