import React, { useState } from "react";
import "../../assets/css/header.css";


const Header = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState(null); // For dropdown toggling

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleMenuClick = (menuIndex) => {
    setActiveMenu(activeMenu === menuIndex ? null : menuIndex);
  };

  return (
    <header className="custom-header">
      <div className="custom-header-container">
        <div className="custom-logo">
          <a href="/">
          <img src="/images/new-website/bhlogo.webp"/>
          </a>
        </div>
        <button
          className="custom-hamburger"
          onClick={toggleMobileMenu}
          aria-label="Toggle menu"
        >
          ☰
        </button>
        <nav className={`custom-nav ${isMobileMenuOpen ? "custom-nav-open" : ""}`}>
          <ul className="custom-menu">
            {/* Menu Item 1 */}
            <li className="custom-menu-item">
              <button
                className="custom-menu-link"
                onClick={() => handleMenuClick(0)}
              >
                BH Services <span className="custom-chevron">{activeMenu === 0 ? "▲" : "▼"}</span>
              </button>
              <ul className={`custom-submenu ${activeMenu === 0 ? "visible" : ""}`}>
                    <li className="custom-submenu-item">
                    <a href="/women-health">Women's Health</a>
                    </li>
                    <li className="custom-submenu-item">
                      <a href="/lab-tests">Lab Tests</a>
                    </li>
                    <li className="custom-submenu-item">
                      <a href="/radiology">Radiology</a>
                    </li>
                    <li className="custom-submenu-item">
                      <a href="/diet-and-nutrition">Diet & Nutrition</a>
                    </li>
                    <li className="custom-submenu-item">
                      <a href="/ai-health-screening">Smart Screening</a>
                    </li>
                    <li className="custom-submenu-item">
                      <a href="/adult-vaccination-program">Adult Vaccines</a>
                    </li>
                    <li className="custom-submenu-item">
                      <a href="/yoga">Yoga & Fitness</a>
                    </li>
                    <li className="custom-submenu-item">
                      <a href="/physiotherapy">Physiotherapy</a>
                    </li>
              </ul>
            </li>

            {/* Menu Item 2 with Submenu */}
            <li className="custom-menu-item">
              <button
                className="custom-menu-link"
                onClick={() => handleMenuClick(1)}
              >
                BH Clinics
              </button>
              
            </li>

            {/* Menu Item 3 with Submenu */}
            <li className="custom-menu-item">
              <a href="/elder-care">
              <button
                className="custom-menu-link"
                onClick={() => handleMenuClick(2)}
              >
                Elder Care
              </button>
              </a>
            </li>

            {/* Menu Item 4 */}
            <a href="https://bridgehealth.in/contact">
            <li className="custom-menu-item">
              
              <button className="custom-menu-link-contact">Contact Us</button>
            
            </li>
            </a>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
