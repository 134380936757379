import React from "react";
import { Link } from "react-router-dom";
import Header from "../layout/headerHome";
import Footer from "../layout/footerUpdated";
import { Helmet } from "react-helmet";
import PhoneInput from "react-phone-number-input";
import ReachToUsForm from "../element/ContactUsForm";
import "../../assets/css/new-website.css"


const ContactNew = () => {
    return (
        <>
        <Header />
        <div className="contact-map"> 
        <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.018675485648!2d77.58522187454818!3d12.906520587402882!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1571e6b9bbaf%3A0xc485c6444a9e56b4!2sBridge%20Health%20Medical%20%26%20Digital%20Solutions%20Private%20Limited!5e0!3m2!1sen!2sin!4v1686708683071!5m2!1sen!2sin"
                  allowfullscreen=""
                  className="mapwidth"
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                  title="contact us"
                  style={{ height: "400px", border: "0px" }} /* Set the height directly */
                ></iframe>
        </div>
        <div className="header-new-main">
            <div className="header-new-left">
            <div className="contact-new-info">
            {/* Chat to Us Section */}
                <div className="contact-item">
                    <div className="contact-icon">
                        <img src="/images/new-website/contact-page/chat.webp" alt="Chat Icon" width="30px"/>
                    </div>
                    <div className="contact-new-details">
                        <h4>Chat to us</h4>
                        <p>Our friendly team is here to help.</p>
                        <a href="info@bridgehealthgroup.com">info@bridgehealthgroup.com</a>
                    </div>
                </div>

                {/* Visit Us Section */}
                <div className="contact-item">
                    <div className="contact-icon">
                        <img src="/images/new-website/contact-page/location.webp" alt="Location Icon" width="50px"/>
                    </div>
                    <div className="contact-new-details">
                        <h4>Visit us</h4>
                        <p>Come say hello at our office HQ.</p>
                        <address>
                        No.166-167, 21st Main Road, 100 Feet Ring Road, 2nd Phase, JP Nagar, Bangalore - 560078
                        </address>
                        
                    </div>
                </div>

                {/* Call Us Section */}
                <div className="contact-item">
                    <div className="contact-icon">
                        <img src="/images/new-website/contact-page/call.webp" alt="Phone Icon" width="30px"/>
                    </div>
                    <div className="contact-new-details">
                        <h4>Call us</h4>
                        <p>Mon-Fri from 8am to 5pm.</p>
                        <a href="tel:+15550000000">+91 8123863522</a>
                    </div>
                </div>
        </div>

            </div>
            <div className="header-new-right">
                <h2>Request a call back </h2>
                <p>Tell us more about yourself and what you have got in mind.</p>
                <ReachToUsForm/>
            </div>
        </div>
        <Footer />
        </>
    );
};

export default ContactNew;
