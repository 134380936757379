import React, { useState, useEffect, useRef } from "react";
import Banner_Slider_Women from "../element/component/Banner_Slider_Women";
import Banner_slider from "../element/component/Banner_slider";
import TestimonialSlider from "../element/component/testimonial-slider";

const WomenCare= () => {

  const [activeIndex, setActiveIndex] = useState(0);

  const testimonials = [
    {
      content:
        "Bridge health offers services at affordable cost. Akshata at the front desk is very cooperative and good at coordinating and follow-ups. The staff are well spoken and carried out all tests with patience.",
      name: "Akshata J Lokhande",
      image: "https://via.placeholder.com/50", // Replace with actual image URL
    },
    {
      content:
        "I am Prof Sundararaj living at PRIMUS REFLECTION. The staff are very courteous with a smile on their faces, including all the doctors. Special thanks to Ms. Akshita!",
      name: "Sundar Raj",
      image: "https://via.placeholder.com/50", // Replace with actual image URL
    },
    {
      content:
        "I am Prof Sundararaj living at PRIMUS REFLECTION. The staff are very courteous with a smile on their faces, including all the doctors. Special thanks to Ms. Akshita!",
      name: "Sundar Raj",
      image: "https://via.placeholder.com/50", // Replace with actual image URL
    },
    {
      content:
        "I am Prof Sundararaj living at PRIMUS REFLECTION. The staff are very courteous with a smile on their faces, including all the doctors. Special thanks to Ms. Akshita!",
      name: "Sundar Raj",
      image: "https://via.placeholder.com/50", // Replace with actual image URL
    },
    {
      content:
        "I am Prof Sundararaj living at PRIMUS REFLECTION. The staff are very courteous with a smile on their faces, including all the doctors. Special thanks to Ms. Akshita!",
      name: "Sundar Raj",
      image: "https://via.placeholder.com/50", // Replace with actual image URL
    },
  ];

  const faqs = [
    {
      question: "What is Bridge Health's senior care?",
      answer:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
    },
    {
      question: "Where is headquarters located? Can I visit?",
      answer: "Our headquarters is located in XYZ. Yes, you can visit us anytime!",
    },
    {
      question: "What areas of service does Bridge Health offer?",
      answer: "We provide services in senior care, healthcare consultations, and more.",
    },
    {
      question: "Can I choose which services I need from senior care?",
      answer: "Yes, you can customize the services you need based on your requirements.",
    },
  ];

  const handleAccordionClick = (index) => {
    setActiveIndex(index === activeIndex ? -1 : index);
  };

      // Slider settings defined here
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    prevArrow: (
      <div className="slick-prev">
        <i className="fa fa-angle-left" aria-hidden="true"></i>
      </div>
    ),
    nextArrow: (
      <div className="slick-next">
        <i className="fa fa-angle-right" aria-hidden="true"></i>
      </div>
    ),
  };

    // Custom slides for this page
    const slides = [
        {
          desktop: "/images/new-website/women-care/prestige.webp",
          mobile: "/images/new-website/women-care/prestige.webp",
        },
        {
          desktop: "/images/new-website/women-care/sobha.webp",
          mobile: "/images/new-website/women-care/sobha.webp",
        },
        {
          desktop: "/images/new-website/women-care/spark.webp",
          mobile: "/images/new-website/women-care/spark.webp",
        },
        {
          desktop: "/images/new-website/women-care/suvidha.webp",
          mobile: "/images/new-website/women-care/suvidha.webp",
        },
      ];


    const slidesData = [
        {
          heading: "Calm Your Mind, Find Your Way,",
          highlightedText: "Women’s Emotional Wellness, Every Day",
          subheading: "Positive Body Image | Work-Life Balance | Enhanced Sleep | Less Anxiety",
          image: "/images/new-website/women-care/women-balance.webp",
        },
        {
          heading: "Stretch, Breathe, Thrive:",
          highlightedText: "Yoga for Women",
          subheading: "Improved Mood | Increased Focus | Hormonal Balance",
          image: "/images/new-website/women-care/women-yoga.webp",
        },
        {
            heading: "Move Freely, Feel Your Best",
            highlightedText: "Physiotherapy for Women",
            subheading: "Pain Relief | Prehab & Rehab Care | Bone Strengthening",
            image: "/images/new-website/women-care/physiotherapy-women.webp",
          },
      ];
      

  const [isMobile, setIsMobile] = useState(false);
  const sectionRefs = useRef([]); // Refs for multiple sections/cards

  // Resize event listener to detect mobile view
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    handleResize(); // Initialize on mount
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Scroll Animation Logic
  useEffect(() => {
    const handleScroll = () => {
      const updatedVisibility = sectionRefs.current.map((ref) => {
        if (ref) {
          const rect = ref.getBoundingClientRect();
          const windowHeight =
            window.innerHeight || document.documentElement.clientHeight;

          // Element becomes visible when its top is within viewport
          return rect.top <= windowHeight - 50;
        }
        return false;
      });
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Trigger on mount to check initial visibility
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const cardsData = [
    { title: "Annual Health Checkup", image: "/images/new-website/women-care/calendar.webp" },
    { title: "Breast Health Examination", image: "/images/new-website/women-care/breast-cancer-awareness.webp" },
    { title: "Fertility Care", image: "/images/new-website/women-care/prenatal-care.webp" },
    { title: "Menopause & Management", image: "/images/new-website/women-care/reproductive.webp" },
    { title: "Cervical Cancer Prevention", image: "/images/new-website/women-care/cervical-cancer.webp" },
    { title: "Pregnancy Care", image: "/images/new-website/women-care/pregnant.webp" },
    { title: "PCOS Management", image: "/images/new-website/women-care/pap-smear.webp" },
    { title: "Sexual Health", image: "/images/new-website/women-care/gender.webp" },
    { title: "Postpartum Care", image: "/images/new-website/women-care/gratitude.webp" },
    { title: "Nutrition Guidance", image: "/images/new-website/women-care/health.webp" },
    { title: "Mental Health Support", image: "/images/new-website/women-care/chiropractic.webp" },
    { title: "Lifestyle Management", image: "/images/new-website/women-care/yoga.webp" },
  ];

  const whyData = [
    { title: "Trusted Expertise", image: "/images/new-website/women-care/expert.webp" },
    { title: "Personalized Solutions", image: "/images/new-website/women-care/puzzle.webp" },
    { title: "Ongoing Support", image: "/images/new-website/women-care/customer-support.webp" },
  ];

  const styles = {
    headingwoman1: {
        fontSize: "50px",
        color:"#0d0d0d",
        fontWeight: "600",
        lineHeight: "1.2",
        textAlign: "left",
        width: "100%"
      },
  };

  return (
    <div>
      <div className={`landingcontainer ${isMobile ? "mobile" : ""}`}>
        <div className={`leftContainer ${isMobile ? "mobile" : ""}`}>
          <h1 style={styles.headingwoman1}>
            Shaping Every Chapter of{" "}
            <span className="highlight1-women">Women's Health</span> With Personalized
            Solutions
          </h1>
          <p className="paragraph">Because We Care To Craft A Healthy Her Story!</p>
          <button className="buttonlanding">Connect With Us</button>
        </div>
        <div className="rightContainer">
          <img
            src="/images/new-website/women-care/women-care.webp"
            alt="Woman smiling"
            className="imagelanding imageFadeInUp"
          />
        </div>
      </div>
      <section>
        <div className="sectiontwo">
          <h2 className="headingsecond">
          <span className="highlight1-women">Wellness That Puts You First</span>
            <br />
            Holistic Health Solutions Designed for Every Woman
          </h2>
        </div>
        <div className={`solutioncontainer ${isMobile ? "mobile" : ""}`}>
          {cardsData.map((card, index) => (
            <div
              key={index}
              className={`card-woman `}
            //   className={`card fade-in-up ${
            //     hasAnimated[index] ? "visible" : ""
            //   }`}
              // onMouseEnter={() => setHoveredSolutionIndex(index)}
              // onMouseLeave={() => setHoveredSolutionIndex(null)}
              // ref={(el) => (sectionRefs.current[index] = el)}
            >
              <img
                src={card.image}
                alt={card.title}
                className="image"
              />
              <p className="solutiontext">
              <span className="solutionbold">{card.title.split(" ")[0]}</span>{" "}
              {card.title.split(" ").slice(1).join(" ")}
              </p>
            </div>
          ))}
        </div>
      </section>
      <section>
        <div className={`strongcontainer ${isMobile ? "mobile" : ""}`}>
          <div className="leftstrongcontainer">
            <img
              src="/images/new-website/women-care/women-nutrition-2.webp"
              alt="Personalized Care"
              className="imagestrong"
            />
          </div>
          <div className="rightstrongcontainer">
            <h2 className="heading1">
            Discover Our Wellness Plans:
            Designed Just For <br />
              <span className="highlight1-women">#StrongHer</span>
            </h2>
            <p className="paragraph">
              Our approach to women’s health is deeply rooted in understanding,
              compassion, and expertise. From preventive care to specialized
              treatments, we prioritize every aspect of your well-being.
            </p>
            <button className="buttonstrong">Learn More</button>
          </div>
        </div>
      </section>
      <section>
        <div className={`aicontainer ${isMobile ? "mobile" : ""}`}>
          <div className="leftaicontainer">
            <h2 className="heading1">
              Leveraging AI for <br />
              <span className="highlight1-women">Personalized Healthcare</span>
            </h2>
            <p className="paragraph">
              Using advanced AI, we bring precision and personalization to your
              health journey. Every decision, every solution is tailored just
              for you.
            </p>
            <button className="buttonai">Discover More</button>
          </div>
          <div className="rightaicontainer">
            <img
              src="/images/new-website/women-care/AI-powered-women-screening.webp"
              alt="AI Technology"
              className="imageai"
            />
          </div>
        </div>
      </section>
      <section className="bannerSliderT1">
      <Banner_Slider_Women slides={slidesData} />;
      <button className="buttonslider">Discover More</button>
      </section>
      <section className="whysection">
        <div className={`whycontainer ${isMobile ? "mobile" : ""}`}>
            <h2 className="whyheading">
            Why Bridge Health is the Trusted Choice for <br />
              <span className="highlight1-women">Comprehensive Women's Care?</span>
            </h2>
            <div className={`whycontainerbox ${isMobile ? "mobile" : ""}`}>
                {whyData.map((card, index) => (
                    <div
                    key={index}
                    // className={`card fade-in-up ${
                    //     hasAnimated[index] ? "visible" : ""
                    // }`}
                    //  onMouseEnter={() => setHoveredSolutionIndex(index)}
                    //  onMouseLeave={() => setHoveredSolutionIndex(null)}
                    //  ref={(el) => (sectionRefs.current[index] = el)}
                    >
                    <div class="whyboxwomen">
                    <img
                        src={card.image}
                        alt={card.title}
                        className="whyimage"
                    />
                    <p className="solutiontext">
                    <span className="solutionbold">{card.title.split(" ")[0]}</span>{" "}
                    {card.title.split(" ").slice(1).join(" ")}
                    </p>
                    </div>
                    </div>
                ))}
            </div>
        </div>
      </section>
      <section className="investsection">
        <div className={`whycontainer ${isMobile ? "mobile" : ""}`}>
            <h2 className="whyheading">
            Invest in,
              <span className="highlight1-women"> Her Wellness</span> Invest <br /> in Your Success!
            </h2>
        </div>
        </section>
        <div className="custom-slider-wrapper slider-invest" style={{padding: "0px 300px", marginTop: "-280px", marginLeft: "0px"}}>
            <Banner_slider sliderSettings={sliderSettings} slides={slides} />
        </div>
        <div className="invest-button" style={{display:"flex", justifyContent:"center", marginTop: "-150px"}}>
        <button className="buttoninvest">Discover More</button>
        </div>

    <section className="faq-updated">
      <div className="faq-container">
        {/* Left Section */}
        <div className="faq-left">
          <h2>
            Frequently <span className="highlight1-women">Asked</span> Questions
          </h2>
        </div>

        {/* Right Section */}
        <div className="faq-right">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className={`faq-item ${activeIndex === index ? "active" : ""}`}
            >
              {/* Title */}
              <div
                className="faq-title"
                onClick={() => handleAccordionClick(index)}
              >
                {faq.question}
                <span className="faq-icon">
                  {activeIndex === index ? "−" : "+"}
                </span>
              </div>

              {/* Content */}
              <div
                className="faq-content"
                style={{
                  height: activeIndex === index ? "auto" : "0",
                  overflow: "hidden",
                  transition: "height 0.3s ease",
                }}
              >
                {faq.answer}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>

    <section>
    <div className="testimonials-container">
      {/* Left Side */}
      <div className="left-section-testimonial">
        <h1>
        Trust Us Based on What Our <span className="highlight1-women">Patrons Say</span>
        </h1>
        <h3>Overall Ratings</h3>
        <div className="ratings">
          <img
            src="/images/new-website/women-care/google-logo-1.webp"
            alt="Google Logo"
          />
          <div>
            <p>
              4.9{" "}
              <span className="stars">
                ⭐⭐⭐⭐⭐
              </span>
            </p>
            <p>Customer Reviews</p>
          </div>
        </div>
        <button>Write a Review</button>
      </div>

      {/* Right Side */}
      <div className="right-section-testimonial">
        <div className="testimonial-page">
      <TestimonialSlider testimonials={testimonials} />
    </div>
      </div>
    </div>
    </section>

    </div>
  );
};

export default WomenCare;